import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'
import Whats from '~/components/Whats'

const Layout = ({ children, location }: PageProps) => (
  <CookiesProvider>
    <TransitionProvider
      location={location}
      mode="immediate"
      enter={{
        opacity: 0,
      }}
      usual={{
        opacity: 1,
      }}
      leave={{
        opacity: 0,
        config: {
          duration: 200,
        },
      }}
    >
      <TransitionViews>{children}</TransitionViews>
    </TransitionProvider>
    <Whats />
  </CookiesProvider>
)

export default Layout
