import React from 'react'

import whats from '~/assets/img/icons/iconewhats.png'

import * as st from '~/assets/styl/Whats.module.styl'

export default function Whats() {
  return (
    <div className={st.whats}>
      <a
        href="https://api.whatsapp.com/send?phone=5548991643688"
        target="_blank"
        rel="noreferrer"
      >
        <img src={whats} alt="Ícone do whatsapp" />
      </a>
    </div>
  )
}
